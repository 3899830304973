<template>
    <div>
        <v-simple-table fixed-header height="calc(100vh - 145px)" class="table-padding-2">
            <template v-slot:default>
                <thead class="v-data-table-header">
                    <tr>
                        <th role="columnheader">
                            <SelectPos :label="$t('labels.pos')" :placeholder="$t('labels.pos')"
                                name="id_pos" sort-name="pos_code" has-sort :sorting="filters.sort_by"
                                @onFilter="onFilterChange" @onSort="onSortChange" />
                        </th>
                        <!-- <th role="columnheader">
                            <InputFilter :label="$t('labels.use')" :placeholder="$t('labels.use')" name="customer"
                                sort-name="customer" has-sort :sorting="filters.sort_by" @onFilter="onFilterChange"
                                @onSort="onSortChange" />
                        </th> -->
                        <th role="columnheader">
                            <InputFilter :label="$t('labels.packaging_code')" :placeholder="$t('labels.packaging_code')"
                                name="packaging_code" sort-name="packaging_code" has-sort :sorting="filters.sort_by"
                                @onFilter="onFilterChange" @onSort="onSortChange" />
                        </th>
                        <th role="columnheader">
                            <SelectFilter :options="packagingTypes" :label="$t('labels.type')"
                                :placeholder="$t('labels.type')" name="type" sort-name="type" has-sort
                                @onFilter="onFilterChange" @onSort="onSortChange" />
                        </th>
                        <th role="columnheader">
                            <InputFilter :label="$t('labels.name')" :placeholder="$t('labels.name')" name="name"
                                sort-name="name" has-sort :sorting="filters.sort_by" @onFilter="onFilterChange"
                                @onSort="onSortChange" />
                        </th>
                        <th role="columnheader">
                            <SelectFilter :options="packagingUnits" :label="$t('labels.unit')"
                                :placeholder="$t('labels.unit')" name="unit" sort-name="unit" has-sort
                                :sorting="filters.sort_by" @onFilter="onFilterChange" @onSort="onSortChange" />
                        </th>
                        <th role="columnheader">
                            <InputFilter :label="$t('labels.size_v')" :placeholder="$t('labels.size_v')" name="size"
                                sort-name="size" has-sort :sorting="filters.sort_by" @onFilter="onFilterChange"
                                @onSort="onSortChange" />
                        </th>
                        <th role="columnheader">
                            <InputFilterFromTo :label="$t('labels.available')" :placeholder="$t('labels.available')"
                                name="available" sort-name="available" has-sort :sorting="filters.sort_by"
                                @onFilter="onFilterChange" @onSort="onSortChange" />
                        </th>
                        <th role="columnheader">
                            {{ $t('labels.forecast_out_of_stock') }}
                            <!-- <InputFilterFromTo :label="$t('labels.forecast_out_of_stock')"
                                :placeholder="$t('labels.forecast_out_of_stock')" name="forecast_out_of_stock"
                                sort-name="forecast_out_of_stock" has-sort :sorting="filters.sort_by"
                                @onFilter="onFilterChange" @onSort="onSortChange" /> -->
                        </th>
                        <th role="columnheader">
                            {{ $t('labels.supplier') }}
                            <!-- <InputFilter :label="$t('labels.supplier')" :placeholder="$t('labels.supplier')" name="supplier"
                                sort-name="supplier" has-sort :sorting="filters.sort_by" @onFilter="onFilterChange"
                                @onSort="onSortChange" /> -->
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, key) in items" :key="`p_${key}_${item.id}`" class="text-center">
                        <td>{{ item.pos_code }}</td>
                        <!-- <td>{{ item.customers || 'Tất cả' }}</td> -->
                        <td>{{ item.code }}</td>
                        <td>{{ item.type }}</td>
                        <td>{{ item.name }}</td>
                        <td>{{ item.unit }}</td>
                        <td>{{ item.dxrxc }}</td>
                        <td>{{ formatNumber(item.quantity) }}</td>
                        <td>{{ item.forecast_out_of_stock ? `${item.forecast_out_of_stock} ngày` : '' }}</td>
                        <td>{{ item.last_supplier }}</td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>

        <v-row class="pt-3">
            <v-col cols="12" sm="4">
                <!-- <v-btn color="info">{{ $t('labels.excel') }}</v-btn> -->
            </v-col>
            <v-col cols="12" sm="4">
                <v-pagination v-model="page" :length="totalPage" :total-visible="5"></v-pagination>
            </v-col>
            <v-col cols="12" sm="4" class="text-right">
                <v-btn color="success" class="mr-2" @click="showImportDialog('import')">{{ $t('labels.import') }}</v-btn>
                <v-btn color="primary" class="mr-2" @click="showImportDialog('export')">{{ $t('labels.export') }}</v-btn>
                <v-btn color="purple" class="white--text" @click="showHistoryDialog">{{ $t('labels.history') }}</v-btn>
            </v-col>
        </v-row>

        <v-dialog v-model="importDialog" persistent max-width="1200px">
            <ImportDialog v-if="importDialog" :create-type="createType" @cancel="hideImportDialog" />
        </v-dialog>

        <v-dialog v-model="historyDialog" persistent max-width="1200px">
            <HistoryDialog v-if="historyDialog" @cancel="hideHistoryDialog" />
        </v-dialog>
    </div>
</template>

<script>
import { YES_NO_OPTIONS, PACKAGING_TYPES, PACKAGING_UNITS } from '@/libs/const';
import { httpClient } from "@/libs/http";
import { debounce } from 'lodash/function';

export default {
    name: "Index",
    components: {
        SelectPos: () => import('@/components/table/SelectPos'),
        SelectFilter: () => import('@/components/table/SelectFilter'),
        InputFilter: () => import('@/components/table/InputFilter'),
        InputFilterFromTo: () => import('@/components/table/InputFilterFromTo'),
        ImportDialog: () => import('@/components/packaging/ImportDialog'),
        HistoryDialog: () => import('@/components/packaging/HistoryDialog'),
    },
    data: () => ({
        page: 1,
        totalPage: 1,
        items: [],
        viewOption: 'view0',
        filters: {},
        yesNoOptions: [...YES_NO_OPTIONS],
        packagingTypes: [...PACKAGING_TYPES],
        packagingUnits: [...PACKAGING_UNITS],
        isLoading: false,
        importDialog: false,
        historyDialog: false,
        updatingItem: {},
        sum: {},
        createType: null,
    }),
    watch: {
        page() {
            this.getList()
        },
        filters: {
            handler() {
                this.page = 1
                this.getList()
            },
            deep: true
        }
    },
    mounted() {
        this.getList()
    },
    methods: {
        showImportDialog(type) {
            this.createType = type
            this.importDialog = true
        },
        hideImportDialog() {
            this.createType = null
            this.importDialog = false
            this.getList()
        },
        showHistoryDialog() {
            this.historyDialog = true
        },
        hideHistoryDialog() {
            this.historyDialog = false
        },
        setViewOption(mode) {
            this.viewOption = mode
        },
        onFilterChange(filter) {
            this.filters = { ...this.filters, [filter.name]: filter.value }
        },
        onSortChange(sort) {
            this.filters = { ...this.filters, ...sort }
        },
        getList: debounce(function () {
            httpClient.post('/packaging-quantity-list', { ...this.filters, page: this.page, viewMode: this.viewOption }).then(({ data }) => {
                this.totalPage = data.totalPage
                this.items = [...data.rows]
                this.sum = { ...data.sum }
            })
        }, 1000),
    }

}
</script>